var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"CWv14iDb-Orc9myiCy9jb"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/browser';
import { getEnv } from './common/config';

Sentry.init({
  dsn: 'https://2f762a95f8b8e9a7c7c2dc4b298f0b2a@o4505123926573056.ingest.sentry.io/4505894148964352',
  environment:
    process.env.NODE_ENV === 'production' ? 'production' : 'development',
  enabled: getEnv() !== 'local',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
});
